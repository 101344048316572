'use client';

import React from 'react';
import useCarouselAnimation from '@src/hooks/useCarouselAnimation';
import useCarouselIndicatorAnimation from '@src/hooks/useCarouselIndicatorAnimation';
import Container from '@src/components/Shared/Container';
import CarouselContainer from '@src/components/Shared/CarouselContainer';
import CarouselIndicators from '@src/components/Shared/CarouselIndicators';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import NextImage from '@src/components/Shared/NextImage';
import ArrowIOS from '@src/components/Icons/ArrowIOS';
import { TestimonialsPropTypes } from './Testimonials.types';
import InspectorWrapper from '@src/components/Shared/InspectorWrapper';
const Testimonials: React.FC<TestimonialsPropTypes> = ({
  attr,
  id,
  testimonials
}) => {
  const {
    carouselRef,
    containerRef,
    handleAddCarouselItemsToRef,
    activeIndex,
    handleShowNextItem,
    handleShowPreviousItem,
    handleJumpToSlide,
    maxIndex
  } = useCarouselAnimation({
    overrideMaxIndex: testimonials?.length - 1
  });
  const {
    handleAddIndicatorRef
  } = useCarouselIndicatorAnimation({});
  return <>
      <Container attr={attr} containerRef={containerRef} id={id} className='relative flex flex-col !overflow-hidden rounded-[0.6rem] py-[unset]' background='lg:!ml-[unset] lg:!mr-[unset] lg:!w-[100%] bg-background-tertiary' data-sentry-element="Container" data-sentry-source-file="Testimonials.tsx">
        {testimonials && testimonials.length > 1 && <div className='md:space-between absolute left-1/2 top-1/2 z-[1000] hidden w-[82%] -translate-x-1/2 -translate-y-1/2 transform lg:flex lg:max-w-[138rem]'>
            <button aria-label='arrow' onClick={handleShowPreviousItem} disabled={activeIndex <= 0} className={`mr-[1.6rem] flex h-[5.5rem] w-[5.5rem] rotate-180 items-center justify-center rounded-full ${activeIndex <= 0 ? 'cursor-not-allowed' : ''}`}>
              <ArrowIOS className='' />
            </button>

            <button aria-label='arrow' onClick={handleShowNextItem} disabled={activeIndex >= testimonials?.length - 1} className={`ml-auto flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-full ${activeIndex >= testimonials?.length - 1 ? 'cursor-not-allowed' : ''}`}>
              <ArrowIOS className='' />
            </button>
          </div>}

        <CarouselContainer carouselRef={carouselRef} className='!mb-[unset]' data-sentry-element="CarouselContainer" data-sentry-source-file="Testimonials.tsx">
          {testimonials?.map((testimonial: any, index: number) => {
          return <div key={index} ref={handleAddCarouselItemsToRef} className='flex min-w-[100%] flex-col justify-center py-[4rem]  md:py-[8.3rem] lg:px-[4.5rem] lg:py-[8rem] [&:not(:last-child)]:mr-[25%]'>
                <RenderBodyCopy className='mx-auto mb-[0] max-w-[50rem] text-center text-[2rem] leading-[2.4rem] text-body-copy--dark md:max-w-[unset] lg:mx-auto lg:max-w-[113rem] lg:px-[5rem] [&>p]:md:text-[2.8rem] [&>p]:md:leading-[4.2rem] [&>p]:lg:text-[3rem] [&>p]:lg:leading-[4.8rem] [&>p]:xl:text-[3.6rem] [&>p]:xl:leading-[4rem] [&>p]:2xl:text-[3.2rem] [&>p]:2xl:leading-[5rem] [&>p]:3xl:px-[0]' entryId={testimonial?.sys?.id} bodyCopy={testimonial?.bodyCopy?.json?.content} />

                <div className='mx-auto mt-[4.2rem] flex max-w-[60%] flex-col sm:mt-[3.2rem] sm:max-w-[42rem] sm:flex-row sm:items-center md:max-w-[48rem] lg:mt-[4rem] 2xl:max-w-[90rem]'>
                  {testimonial?.profileImage?.url && <div className='flex justify-center w-full sm:w-auto '>
                      <NextImage className='mb-[2.4rem] w-[14rem] sm:mb-[unset] lg:relative lg:z-[-5] lg:block 2xl:w-[18rem]' entryId={testimonial?.sys?.id} fieldId='profileImage' src={testimonial?.profileImage?.url} alt={testimonial?.profileImage?.description} objectFit='contain' />
                    </div>}

                  <div>
                    <InspectorWrapper entryId={testimonial?.sys?.id} fieldId='name'>
                      <p className='mb-[1.3rem] text-center text-[1.6rem] font-[500] text-body-copy--dark md:max-w-[45rem] md:text-[1.6rem] 2xl:max-w-[60rem] 2xl:text-[2.4rem] 2xl:leading-[3.6rem]'>
                        {testimonial?.name}
                      </p>
                    </InspectorWrapper>
                    <InspectorWrapper entryId={testimonial?.sys?.id} fieldId='title'>
                      <p className='mb-[1rem] text-center text-[1.4rem] text-body-copy--dark md:text-[1.6rem]  2xl:text-[2rem] 2xl:leading-[3.6rem]'>
                        {testimonial?.title}
                      </p>
                    </InspectorWrapper>
                    <InspectorWrapper entryId={testimonial?.sys?.id} fieldId='company'>
                      <p className='text-center text-[1.4rem] text-body-copy--dark md:text-[1.6rem] 2xl:text-[2rem] 2xl:leading-[3.6rem]'>
                        {testimonial?.company}
                      </p>
                    </InspectorWrapper>
                  </div>
                </div>
              </div>;
        })}
        </CarouselContainer>
        <CarouselIndicators className='mb-[4.2rem] flex justify-center md:mb-[7.2rem]' maxIndex={maxIndex} handleJumpToSlide={handleJumpToSlide} handleAddIndicatorRef={handleAddIndicatorRef} activeIndex={activeIndex} data-sentry-element="CarouselIndicators" data-sentry-source-file="Testimonials.tsx" />
      </Container>
    </>;
};
export default Testimonials;